
import {defineComponent, reactive, toRefs, onBeforeMount, onMounted} from 'vue';
import avator from '@/components/tools/avator.vue';
import {useRouter} from 'vue-router';
import searchBox from '@/components/tools/search.vue';
import axios from '@/api/http.js';

export default defineComponent({
  name: 'Mec',
  components: {
    avator,
    searchBox
  },
  setup() {
    const route = useRouter();
    const data = reactive({
      user: JSON.parse(localStorage.getItem('campusInfo')),
      allEventTotal: 0,
      currentEventTotal: 0,
      upcomingEventTotal: 0,
      pastEventTotal: 0,
      select: '',
      options: [
        {label: 'Edit My Profile', value: '1'},
        // {label: 'My Events', value: '2'},
        {label: 'Log Out', value: '3'},
      ],
      events: [
        // {
        //   name: 'CURRENT EVENTS',
        //   count: 0,
        //   data: [
        //     {
        //       img: require('../assets/imgs/event.png'),
        //       name: 'Vmware Local Events',
        //       desc: ' Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        //       data: [
        //         {
        //           img: require('../assets/imgs/event.png'),
        //           name: 'Marketing Concierge-Virtual Platform',
        //           date: 'Saturday,July 31,2021',
        //         },
        //         {
        //           img: require('../assets/imgs/event.png'),
        //           name: 'Lorem ipsum',
        //           date: 'Sunday,August 1,2021',
        //         },
        //         {
        //           img: require('../assets/imgs/event.png'),
        //           name: 'Lorem',
        //           date: 'Monday,August 2,2021',
        //         },
        //       ]
        //     },
        //     {
        //       img: require('../assets/imgs/event.png'),
        //       name: 'Vmware Local Events',
        //       desc: ' Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        //       data: [
        //         {
        //           img: require('../assets/imgs/event.png'),
        //           name: 'Marketing Concierge-Virtual Platform',
        //           date: 'Saturday,July 31,2021',
        //         },
        //         {
        //           img: require('../assets/imgs/event.png'),
        //           name: 'Lorem ipsum',
        //           date: 'Sunday,August 1,2021',
        //         },
        //         {
        //           img: require('../assets/imgs/event.png'),
        //           name: 'Lorem',
        //           date: 'Monday,August 2,2021',
        //         },
        //       ]
        //     },
        //   ]
        // },
        // {
        //   name: 'UPCOMING EVENTS',
        //   count: 0,
        //   data: [
        //     {
        //       img: require('../assets/imgs/event.png'),
        //       name: 'Vmware Local Events',
        //       desc: ' Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        //       data: [
        //         {
        //           img: require('../assets/imgs/event.png'),
        //           name: 'Marketing Concierge-Virtual Platform',
        //           date: 'Saturday,July 31,2021',
        //         },
        //         {
        //           img: require('../assets/imgs/event.png'),
        //           name: 'Lorem ipsum',
        //           date: 'Sunday,August 1,2021',
        //         },
        //         {
        //           img: require('../assets/imgs/event.png'),
        //           name: 'Lorem',
        //           date: 'Monday,August 2,2021',
        //         },
        //       ]
        //     },
        //     {
        //       img: require('../assets/imgs/event.png'),
        //       name: 'Vmware Local Events',
        //       desc: ' Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        //       data: [
        //         {
        //           img: require('../assets/imgs/event.png'),
        //           name: 'Marketing Concierge-Virtual Platform',
        //           date: 'Saturday,July 31,2021',
        //         },
        //         {
        //           img: require('../assets/imgs/event.png'),
        //           name: 'Lorem ipsum',
        //           date: 'Sunday,August 1,2021',
        //         },
        //         {
        //           img: require('../assets/imgs/event.png'),
        //           name: 'Lorem',
        //           date: 'Monday,August 2,2021',
        //         },
        //       ]
        //     },
        //   ]
        // },
        // {
        //   name: 'PAST EVENTS',
        //   count: 0,
        //   data: [
        //     {
        //       img: require('../assets/imgs/event.png'),
        //       name: 'Vmware Local Events',
        //       desc: ' Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        //       data: [
        //         {
        //           img: require('../assets/imgs/event.png'),
        //           name: 'Marketing Concierge-Virtual Platform',
        //           date: 'Saturday,July 31,2021',
        //         },
        //         {
        //           img: require('../assets/imgs/event.png'),
        //           name: 'Lorem ipsum',
        //           date: 'Sunday,August 1,2021',
        //         },
        //         {
        //           img: require('../assets/imgs/event.png'),
        //           name: 'Lorem',
        //           date: 'Monday,August 2,2021',
        //         },
        //       ]
        //     },
        //     {
        //       img: require('../assets/imgs/event.png'),
        //       name: 'Vmware Local Events',
        //       desc: ' Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        //       data: [
        //         {
        //           img: require('../assets/imgs/event.png'),
        //           name: 'Marketing Concierge-Virtual Platform',
        //           date: 'Saturday,July 31,2021',
        //         },
        //         {
        //           img: require('../assets/imgs/event.png'),
        //           name: 'Lorem ipsum',
        //           date: 'Sunday,August 1,2021',
        //         },
        //         {
        //           img: require('../assets/imgs/event.png'),
        //           name: 'Lorem',
        //           date: 'Monday,August 2,2021',
        //         },
        //       ]
        //     },
        //   ]
        // },
      ],
    });
    const clickOption = (data) => {
      console.log('data:', data);
      if (data.value == '2') {
        route.push({name: 'Mec'});
      } else if (data.value == '3') {
        route.push({name: 'Login'});
      }
    };
    const clickArrow = (data) => {
      console.log("data:", data);
      data.showMore = !data.showMore;
    };
    const goEvent = (data) => {
      localStorage.eventsMeetingId = data.id;
      route.push({name: 'Home'})
    };
    // 获取mec
    const getMec = () => {
      axios.request({
        method: 'patch',
        url: 'vmWare/mec',
        data: {}
      }).then(res => {
        // console.log('res:', res);
        formatMec(res.data);
      }).catch(err => {
        console.log(err);
      })
    }
    getMec();
    // 格式化mec的数据
    const formatMec = (mec) => {
      console.log("====Mec =====", mec)
      data.allEventTotal = mec.total_events;
      data.currentEventTotal = mec.current_events;
      data.upcomingEventTotal = mec.upcoming_events;
      data.pastEventTotal = mec.past_events;
      //current
      let objCurrent = {};
      objCurrent["name"] = 'CURRENT EVENTS';
      objCurrent["count"] = data.currentEventTotal;
      objCurrent["data"] = [];
      mec.current_venues.map(venues => {
        let obj = {};
        obj["img"] = venues.image;
        obj["name"] = venues.name;
        obj["desc"] = venues.description;
        obj["data"] = [];
        venues.meetings.map(meeting=>{
          let obj2 = {};
          obj2["img"] = meeting.image;
          obj2["name"] = meeting.name;
          obj2["sub1"] = meeting.sub1;
          obj2["sub2"] = meeting.sub2;
          obj2["id"] = meeting.id;
          obj["data"].push(obj2);
        })
        objCurrent["data"].push(obj);
      })
      data.events.push(objCurrent);
      // upcoming
      let objUpcoming = {};
      objUpcoming["name"] = 'UPCOMING EVENTS';
      objUpcoming["count"] = data.upcomingEventTotal;
      objUpcoming["data"] = [];
      mec.upcoming_venues.map(venues => {
        let obj = {};
        obj["img"] = venues.image;
        obj["name"] = venues.name;
        obj["desc"] = venues.description;
        obj["data"] = [];
        venues.meetings.map(meeting=>{
          let obj2 = {};
          obj2["img"] = meeting.image;
          obj2["name"] = meeting.name;
          obj2["sub1"] = meeting.sub1;
          obj2["sub2"] = meeting.sub2;
          obj2["id"] = meeting.id;
          obj["data"].push(obj2);
        })
        objUpcoming["data"].push(obj);
      })
      data.events.push(objUpcoming);
      // past
      let objPast = {};
      objPast["name"] = 'PAST EVENTS';
      objPast["count"] = data.pastEventTotal;
      objPast["data"] = [];
      mec.past_venues.map(venues => {
        let obj = {};
        obj["img"] = venues.image;
        obj["name"] = venues.name;
        obj["desc"] = venues.description;
        obj["data"] = [];
        venues.meetings.map(meeting=>{
          let obj2 = {};
          obj2["img"] = meeting.image;
          obj2["name"] = meeting.name;
          obj2["sub1"] = meeting.sub1;
          obj2["sub2"] = meeting.sub2;
          obj2["id"] = meeting.id;
          obj["data"].push(obj2);
        })
        objPast["data"].push(obj);
      })
      data.events.push(objPast);
      console.log("==== events=====", data.events)
    };
    // onBeforeMount(() => {
    //   console.log('2.组件挂载页面之前执行----onBeforeMount');
    //   data.events.map(item=>{
    //     item.data.showMore = false;
    //   })
    //   console.log("data.events:",data.events);
    // });
    onMounted(() => {
    });
    return {
      ...toRefs(data),
      clickOption,
      clickArrow,
      goEvent,
      getMec,
      formatMec
    };

  }
});
